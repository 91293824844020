@font-face {
    font-family: 'PingFangSC'; 
    src: url('../assets/font/PingFangSC.ttf'); 
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Gilroy'; 
    src: url('../assets/font/gilroy-black-6.otf'); 
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'YouSheBiaoTiHei'; 
    src: url('../assets/font/YouSheBiaoTiHei.ttf'); 
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'SourceHanSansSC'; 
    src: url('../assets/font/SourceHanSansSC-Medium.otf'); 
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'SourceHanSansSC-Normal'; 
    src: url('../assets/font/SourceHanSansSC-Normal.otf'); 
    font-weight: normal;
    font-style: normal;
}

