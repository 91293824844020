
*{
    padding: 0;
    margin: 0;
}
html {  
    scroll-behavior: smooth;  
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    ::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }
body{
    min-width: 375px;
    max-width:3000px;
    margin: 0 auto;
}
.App-header{
    width: 100%;
    height: 4.86rem;
    position: relative;
    overflow: hidden;
}
.App-header>img{
    width: 100%;
}
.App-header .title{
    position: absolute;
    top: 2rem;
    right: 0.63rem;
    color: white;
}
.moblie_logo{
    display: none;
}
.nav_title{
    position: fixed;
    z-index: 9;
    top: 0;
    height: 64px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}
.nav_title .logo{
    position: absolute;
    left: 120px;
    height: 100%;
}
.topshow{
    background-color: #000000;
    padding-bottom: 1px;
}
.nav_title .center{
    height: 100%;
    display: flex;
    justify-content: center;
}
.nav_title .center .li{
    margin-right: 42px;
    font-family: SourceHanSansSC, SourceHanSansSC;
    font-weight: 400;
    font-size: 16px;
    line-height: 64px;
    text-align: left;
    cursor: pointer;
    display: block;
    color: #FFFFFF;
}
.nav_title .center .li:hover{
    /* background-color: rgba(225, 225, 225, 0.3); */
}
.center  a{
    text-decoration: none;
    color: #FFFFFF;
}
.center .active{
    position: relative;
    border-bottom: 1px solid #FFFFFF;
}
.li .jianjian{
    display: none;
}
.active .jianjian{
    position: absolute;
    width: 20px;
    bottom: 0px;
    left: 50%;
    display: block;
    margin-left: -10px;
}
.title p:nth-child(1){
    font-family: Gilroy, Gilroy;
    font-weight: 900;
    font-size: 0.27rem;
    line-height: 0.3rem;
    text-align: left;
    font-style: normal;
}
.title p:nth-child(2){
    font-family: Gilroy, Gilroy;
    font-weight: 900;
    font-size: 0.625rem;
    line-height: 0.7rem;
    text-align: left;
    font-style: normal;
}
.title p:nth-child(3){
    font-family: YouSheBiaoTiHei;
    font-size: 0.12rem;
    line-height: 0.23rem;
    text-align: left;
    font-style: normal
}
.title p:nth-child(3) span{
    font-size: 0.16rem;
}
.Introduction{
    padding: 30px 0;
    background-color: #F8F8F8;
}
.underlined_Title{
    font-family: SourceHanSansSC, SourceHanSansSC;
    font-weight: 500;
    font-size: 18px;
    color: #000000;
    line-height: 26px;
    text-align: center;
    height: 26px;
    width: 100%;
}
.underlined_Title .lined-text{
    position: relative;  
    display: inline-block;  
    text-align: center;
}
.lined-text::before,  
.lined-text::after {  
    content: "";  
    position: absolute;  
    top: 50%;  
    width: 90px; /* 调整线条长度 */  
    height: 1px; /* 线条的粗细 */  
    background: #979797; /* 线条颜色 */  
}  
.lined-text::before {  
    right: 100%; /* 线条在文本左边 */  
    margin-right: 20px; /* 调整间距 */  
}  
.lined-text::after {  
    left: 100%; /* 线条在文本右边 */  
    margin-left: 20px; /* 调整间距 */  
}
.Introduction>p{
    width: 64%;
    text-align: center;
    margin: 0 auto;
    margin-top: 20px;
    font-family: SourceHanSansSC, SourceHanSansSC;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    line-height: 26px;
}
/* 创始人 pc*/
.section{
    width: 100%;
    background-color: white;
    /* padding:  80px 0; */
    padding-bottom: 0;
}
.section .myswiper{
    height: 372px;
    overflow: hidden;
    margin-top: 40px;
}
.myswiper .swiper{
    height: 100%;
}
.item {
    /* margin: 50px 0; */
    width: 100%;
    height: 100%;
    /* height: 320px; */
    /* background-color: #979797;
    box-shadow: 3px 6px 8px #dde0e8; */
    /* position: absolute; */
    z-index: 1;
}
.swiper .swiper-slide{
    /* background: red; */
    height: 100%;
    width: 60% !important;
    position: relative;
}
.swiper-slide-prev{
    position: relative;
}
.swiper-slide-prev::before{
    content: '';
    width: 100%;
    z-index: 1;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: hsla(0, 0%, 100%, 0.8);
}
.swiper-slide-next::before{
    content: '';
    width: 100%;
    z-index: 1;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: hsla(0, 0%, 100%, 0.8);
}
.swiper-slide-next{
    background-color: rgba(225, 225, 225, 0.5)!important;
    z-index: 100;
}
.my-bullet{
    background-color: white;
    width: 10px;
    height: 10px;
}
.swiper-pagination-bullet{
    background-color: #FFFFFF;
}
.swiper-pagination-bullet-active{
    background-color: #313235;
}
/*  */
.item>img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position:left; 
}
.swiper-slide-active{
    min-width:234px;
}
.list_item{
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
    padding-left: 8%;
    padding-right: 8%;
}
.scroll_item{
    flex: 1;
    display: flex;
    justify-content: space-between;
}
.list_item_div{
    width: 2.6rem;
    min-height: 3.5rem;
    overflow: hidden;
    background: #F8F8F8;
}
.list_item_div:hover{
    /* background-color: rgba(0, 0, 0, .3); */
}
.list_item_img{
    width: 78%;
    height: 80%;
    overflow: hidden;
    background-color: #231916;
    margin: 40px auto;
    margin-bottom: 10px;
}
.list_item_img>img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position:top; 
}
.list_item_div p{
    /* width: 78%; */
    margin: 0 auto;
    margin-left: 12%;
}
.list_item_div p:nth-of-type(1){
    font-weight: 600;
    font-size: 0.12rem;
    color: #000000;
    line-height: 0.17rem;
    height: 0.17rem;
    font-family: PingFangSC, PingFang SC;
}
.list_item_div p:nth-of-type(2){
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #000000;
    line-height: 16px;
    text-align: left;
    height: auto;
    margin-top: 5px;
}

/* 学生展示 */
.studentShow{
    padding: 40px 8%;
    background: #F8F8F8;
}
.studentShow .show{
    margin: 40px auto;
    margin-bottom: 0;
    width: 100%;
    /* height: 522px; */
    position: relative;
}
.studentShow .show>img{
    width: 100%;
}
.studentShow .show .zone{
    position: absolute;
    background-color: #979797;
}
.studentShow .show .zone_1{
    width: 12%;
    height: 19%;
    top: 50%;
    margin-top: -8%;
}
.studentShow .show .zone_2{
    width: 13%;
    height: 19%;
    top: 13%;
    left: 15%;
}
.studentShow .show .zone_3{
    width: 120px;
    height: 200px;
    top: 50%;
    left: 180px;
    margin-top: -30px;
}
.studentShow .show .zone_4{
    width: 160px;
    height: 120px;
    top: 0;
    left: 50%;
    margin-left: -200px;
}
.studentShow .show .zone_5{
    width: 400px;
    height: 200px;
    top: 50%;
    left: 50%;
    margin-left: -200px;
    margin-top: -100px;
}
.studentShow .show .zone_6{
    width: 150px;
    height: 100px;
    top: 0;
    left: 50%;
    margin-left: 50px;
    margin-top: 30px;
}
.studentShow .show .zone_7{
    width: 150px;
    height: 100px;
    bottom: 0;
    left: 50%;
    margin-left: -200px;
    margin-bottom: 30px;
}
.studentShow .show .zone_8{
    width: 160px;
    height: 120px;
    bottom: 0;
    left: 50%;
    margin-left: 40px;
}
.studentShow .show .zone_9{
    width: 120px;
    height: 200px;
    top: 0%;
    right: 200px;
    margin-top: 30px;
}
.studentShow .show .zone_10{
    width: 220px;
    height: 120px;
    top: 50%;
    right: 100px;
    margin-top: 10px;
}
.studentShow .show .zone_11{
    width: 150px;
    height: 100px;
    top: 50%;
    margin-top: -100px;
    right: 0;
}

/* 地址 */
.mapList{
    padding: 80px 8%;
    background-color: white;
}
.mapList .map_div{
    margin-top: 40px;
    display: flex;
    justify-content: space-between;

}
.map{
    width: 30%;
}
.map>img{
    width: 100%;
}
.map p{
    margin-top: 20px;
    font-family: SourceHanSansSC, SourceHanSansSC;
    font-weight: 400;
    font-size: .12rem;
    color: #000000;
    line-height: 17px;
    text-align: center;
}
.footer{
    height: 50px;
    background: #231916;
}
.btn{
    display: none;
}
.content_btn{
    display: block;
}
#founder,#student,#teacher,#appdownload{
    display: block;
    height: 80px;
}
/* 下载 */
.appContent{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.appContent .download_app{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.download_app {
    margin-left: 80px;
}
.download_app .applogo{
    width: 100px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #231916;
    line-height: 22px;
    text-align: center;
    margin-bottom: 75px;
}
.applogo img{
    width: 100%;
    margin-bottom: 5px;
}
.download_app .android{
    margin-top: 24px;
}
.android,.ios{
    width: 300px;
    height: 64px;
    background: #231916;
    font-family: SourceHanSansSC, SourceHanSansSC;
    font-weight: 500;
    font-size: 20px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.android span, .ios span {  
    display: inline-block; /* or block */  
    margin-left: 12px;  
} 
.android img,.ios img{
    width: 48px;
}
.footer{
    font-size: 12px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: SourceHanSansSC, SourceHanSansSC;
    p{
        margin-right: 6px;
    }
}
/* ipad样式 */  
@media (max-width:1024px) {  
    .nav_title {  
        display: none;
    }  
    .moblie_logo{
        display: block;
        height: 0.64rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: absolute;
        top: 0;
        padding: 0 20px;
    }
    .moblie_logo .logo{
        width:0.3rem;
    }
    .App-header .title{
        position: absolute;
        top: 1.8rem;
        right: 0.67rem;
        color: white;
    }
    .title p:nth-child(1){
        font-family: Gilroy, Gilroy;
        font-weight: 900;
        font-size: 0.2rem;
        line-height: 0.3rem;
        text-align: left;
        font-style: normal;
    }
    .title p:nth-child(2){
        font-family: Gilroy, Gilroy;
        font-weight: 900;
        font-size: 0.5rem;
        line-height: 0.6rem;
        text-align: left;
        font-style: normal;
    }
    .title p:nth-child(3){
        font-family: YouSheBiaoTiHei;
        font-size: 0.18rem;
        line-height: 0.34rem;
        text-align: left;
        font-style: normal
    }
    .title p:nth-child(3) span{
        font-size: 0.24rem;
    }
    /* 关于 */
    .Introduction{
        padding: 0.2rem 0;
    }
    .Introduction>p{
        width: 95%;
        text-align: center;
        margin: 0 auto;
        margin-top: 40px;
        font-family: SourceHanSansSC, SourceHanSansSC;
        font-weight: 400;
        font-size: 16px;
        color: #000000;
        line-height: 26px;
    }
    .content_btn{
        display: block;
    }
    /* 老师哦 */
    .list_item{
        display: flex;
        justify-content: flex-start;
    } 
    .scroll_item{
        flex: 1;
        display: flex;
        justify-content: space-between;
    }
    .list_item_img{
        width: 1.58rem;
        height: 2.11rem;
        overflow: hidden;
        background-color: #231916;
        margin: 0.2rem auto;
        margin-bottom: 10px;
    }
    .list_item_div{
        width: 31%;
        min-height: 3.16rem;
        overflow: hidden;
        background: #F8F8F8;
    }
    .list_item_div p:nth-of-type(1){
        font-weight: 600;
        font-size: 0.18rem;
        color: #000000;
        line-height: 0.22rem;
        height: 0.22rem;
        font-family: PingFangSC, PingFang SC;
    }
    .list_item_div p:nth-of-type(2){
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #000000;
        line-height: 12px;
        text-align: left;
        height: 0.14rem;
    }
    /* 下载 */
    .appContent{
        display: flex;
        justify-content: center;
        margin-top: 30px;
    }
    .appContent>img{
        width: 3.78rem;
        height: 3.86rem;
        flex-shrink: 0;
    }
    .appContent .download_app{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    .download_app {
        margin-left: 50px;
        padding: 6px 0;
    }
    .download_app .applogo{
        width:80px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: .3rem;
        color: #231916;
        text-align: center;
        margin-bottom: 0px;
    }
    .applogo img{
        width: 100%;
        margin-bottom: 0px;
    }
    .applogo span{
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 0.16rem;
        color: #231916;
    }
    .download_app .android{
        margin-top: -10px;
    }
    .android,.ios{
        width: 2.69rem;
        height: 0.64rem;
        background: #231916;
        font-family: SourceHanSansSC, SourceHanSansSC;
        font-weight: 400;
        font-size: 0.18rem;
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .android span, .ios span {  
        display: inline-block; /* or block */  
        margin-left: 4px;  
    } 
    .android img,.ios img{
        width: 0.42rem;
    }
  } 
  /* 移动端 */
  @media (max-width:750px) { 
    .underlined_Title{
        font-weight: 500;
        font-size: 16px;
        color: #000000;
        line-height: 24px;
    }
    .lined-text::before,  
    .lined-text::after {  
        content: "";  
        position: absolute;  
        top: 50%;  
        width: 50px; /* 调整线条长度 */  
        height: 1px; /* 线条的粗细 */  
        background: #979797; /* 线条颜色 */  
    }  
    .lined-text::before {  
        right: 100%; /* 线条在文本左边 */  
        margin-right: 15px; /* 调整间距 */  
    }  
    .lined-text::after {  
        left: 100%; /* 线条在文本右边 */  
        margin-left: 15px; /* 调整间距 */  
    }
    .Introduction>p{
        font-weight: 400;
        font-size: 12px;
        color: #313235;
        line-height: 20px;
        text-align: center;
        font-family: 'SourceHanSansSC-Normal';
    }
    /* 创始人 */
    .section{
        padding: 0 0;
    }
    .btn{
        display: flex;
        /* width: 3.25rem;
        height: 0.58rem; */
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        color: #FFFFFF;
        margin: 16PX auto;
        
    }
    .btn span{
        min-width: 2rem;
        background-color: #000000;
        padding: 5px 6px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;    
    }
    .btn span>img{
        margin-left: 2px;
    }
    /* .content_btn{
        display: none;
    } */
         /* 老师哦 */
    .scroll_item{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: fit-content;
    }
    .list_item{
        display: block;
        margin-top: 20px;
        overflow-x: auto;
        padding: 0
    }
    .list_item_div{
        width: 3.94rem;
        min-height: 6.373rem;
        margin-right: 10px;
        overflow: hidden;
        background: #F8F8F8;
        float: left;
        flex-shrink: 0;
    }
    .list_item_img{
        width: 3.12rem;
        height: 4.16rem;
        overflow: hidden;
        background-color: #231916;
        margin: 0.2rem auto;
        margin-bottom: 10px;
    }
    .list_item_div p:nth-of-type(1){
        font-weight: 600;
        font-size: 0.38rem;
        color: #000000;
        line-height: 0.4rem;
        height: 0.4rem;
        font-family: PingFangSC, PingFang SC;
    }
    .list_item_div p:nth-of-type(2){
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #000000;
        line-height: 16px;
        text-align: left;
        height: auto;
        margin-top: 0.1rem;
    }
    /* .swiper-slide-active{
        background-color: aqua;
    } */
    #founder,#student,#teacher,#appdownload{
        width: 100%;
        height: 40px;
        display: block;
    }
    /* 地图 */
    .mapList{
        padding: 40px 8%;
    }
    .section .myswiper {
        /* background-color: #979797; */
        margin-top: 20px;
        height: 150px;
    }
    .Introduction>p {
        width: 95%;
        text-align: center;
        margin: 0 auto;
        margin-top: 10px;
    }
    .map p{
        margin-top: 10px;
        font-family: SourceHanSansSC, SourceHanSansSC;
        font-weight: 400;
        font-size: .3rem;
        color: #000000;
        line-height: .4rem;
        text-align: center;
    }
    /* 下载 */
    .appContent{
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }
    .appContent>img{
        width: 4.53rem;
        height: 5.46rem;
        flex-shrink: 1;
    }
    .appContent .download_app{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    .download_app {
        margin-left: 9px;
    }
    .download_app .applogo{
        width: 64px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: .3rem;
        color: #231916;
        line-height:.6rem;
        text-align: center;
        margin-bottom: 0px;
    }
    .applogo img{
        width: 100%;
        margin-bottom: 0px;
    }
    .download_app .android{
        margin-top: 10px;
    }
    .android,.ios{
        width: 4.5rem;
        height: .9rem;
        background: #231916;
        font-family: SourceHanSansSC, SourceHanSansSC;
        font-weight: 400;
        font-size: 12px;
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .android span, .ios span {  
        display: inline-block; /* or block */  
        margin-left: 4px;  
    } 
    .android img,.ios img{
        width:0.7rem;
    }
  }

  @media (max-width:375px) {
    html{
        font-size: 37.5px;
    }
  }


  

